
import { SlideYDownTransition } from 'vue2-transitions'

export default {
  components: {
    SlideYDownTransition,
  },

  data() {
    return {
      isVisible: false,
    }
  },

  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener)
  },

  methods: {
    scrollTop() {
      if (process.client) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    },

    scrollListener() {
      this.isVisible =
        window.scrollY > window.innerHeight + window.innerHeight / 2
    },
  },
}
