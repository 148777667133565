const defaultState = () => ({
  countries: [],
  specialties: [],
  specialtiesById: {},
  regions: [
    'Europe',
    'Asia Pacific',
    'Middle East and Africa',
    'North America',
    'Latin America',
  ],
})

export const state = defaultState

export const getters = {
  specialtyCategories: (state) => {
    return state.specialties.filter((specialty) =>
      [null, undefined, ''].includes(specialty.parent)
    )
  },
}

export const mutations = {
  SET_COUNTRIES(state, payload) {
    state.countries = payload
  },

  SET_SPECIALTIES(state, payload) {
    state.specialties = payload

    payload.forEach((value) => {
      state.specialtiesById[value.id] = value
    })
  },

  ADD_SPECIALTY(state, payload) {
    state.specialties.push(payload)

    state.specialtiesById[payload.id] = payload
  },

  REMOVE_SPECIALTY(state, id) {
    state.specialties = state.specialties.filter(
      (specialty) => specialty.id !== id
    )

    delete state.specialtiesById[id]
  },

  RESET(state) {
    const initial = defaultState()

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

export const actions = {
  async getCountries({ commit }) {
    const { data } = await this.$api.wbn.countries.getCountries({
      params: {
        sort_by: 'name',
        dir: 'asc',
        limit: 9999,
      },
    })

    await commit('SET_COUNTRIES', data.results)
  },

  async getSpecialties({ commit }) {
    const { data } = await this.$api.wbn.specialties.getSpecialties({
      params: {
        sort_by: 'name',
        dir: 'asc',
        limit: 9999,
      },
    })
    await commit('SET_SPECIALTIES', data.results)
  },

  async getData({ dispatch, state }) {
    const promises = []
    if (!state.specialties.length) promises.push(dispatch('getSpecialties'))
    if (!state.countries.length) promises.push(dispatch('getCountries'))

    if (promises.length) {
      await Promise.all(promises)
    }
  },

  async removeSpecialty({ commit }, id) {
    const result = await this.$api.wbn.specialties
      .deleteSpecialty(id)
      .then((res) => res.data)

    await commit('REMOVE_SPECIALTY', id)

    return result
  },

  async addSpecialty({ commit }, payload) {
    const result = await this.$api.wbn.specialties
      .createSpecialty(payload)
      .then((res) => res.data)

    await commit('ADD_SPECIALTY', result)
    return result
  },

  async reset({ commit }) {
    return await commit('RESET')
  },
}
