
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    crumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    startCase(string) {
      string = string.toLowerCase().split(' ')
      const final = []
      for (const word of string) {
        final.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return final.join(' ')
    },
  },
}
