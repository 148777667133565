
import { mapState, mapActions } from 'vuex'
import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  components: {
    SlideXLeftTransition,
  },

  props: {
    alt: {
      type: String,
      default: '',
    },

    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('user', ['avatarImageId']),

    avatarImagePath() {
      if (this.avatarImageId || this.$auth.user.photo) {
        return this.$helpers.cfImage(
          this.avatarImageId || this.$auth.user.photo
        )
      }

      return require('@/assets/images/default.jpg')
    },
  },

  async created() {
    if (!this.avatarImageId) await this.getUserAvatar()
  },

  methods: {
    ...mapActions('user', ['getUserAvatar']),
  },
}
