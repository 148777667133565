
export default {
  props: {
    result: {
      type: Object,
      required: true,
    },

    highlightResult: {
      type: Object,
      default: () => ({}),
    },

    snippetResult: {
      type: Object,
      default: () => ({}),
    },
  },
}
