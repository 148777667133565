
import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  components: {
    SlideXLeftTransition,
  },

  props: {
    heading: {
      type: String,
      default: '',
    },

    subHeading: {
      type: String,
      default: '',
    },

    breadcrumbs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },

    page: {
      type: Object,
      default: () => ({
        title: 'Default Title',
        path: '/',
      }),
    },
  },
}
