
import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  components: {
    SlideXLeftTransition,
  },

  props: {
    articles: {
      type: Array,
      // required: true,
      default: () => [],
    },

    loading: {
      type: Boolean,
      // required: true,
      default: false,
    },

    speed: {
      type: Number,
      default: 4000,
    },
  },

  data() {
    return {
      index: 0,
      interval: null,
      localArticles: [...this.articles],
    }
  },

  computed: {
    currentArticle() {
      return this.localArticles[this.index]
    },
  },

  async mounted() {
    if (!this.loading && !this.articles.length) {
      try {
        const { data } = await this.$api.ltng.pages.getPages({
          params: {
            account_id: this.$config.ltngId,
            page_type: 'article',
            limit: 10,
          },
        })

        this.localArticles = data.results
      } catch (error) {
        this.$bugsnag.notify(error)
      }
    }

    this.interval = setInterval(() => {
      if (this.index < this.localArticles.length - 1) {
        this.index++
      } else {
        this.index = 0
      }
    }, this.speed)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },
}
