
import { FadeTransition } from 'vue2-transitions'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    FadeTransition,
  },

  computed: {
    ...mapState(['ui']),
  },

  methods: {
    ...mapActions('ui', ['closeNav', 'openNav', 'toggleNavSearch']),
  },
}
