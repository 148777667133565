
export default {
  props: {
    text: {
      type: String,
      default: 'Button Text',
    },

    arialabel: {
      type: String,
      default: '',
    },

    small: {
      type: Boolean,
      default: false,
    },

    to: {
      type: String,
      default: '',
    },
  },
}
