
export default {
  name: 'ButtonLink',
  props: {
    text: {
      type: String,
      default: 'Button Text',
    },
    path: {
      type: String,
      default: '/',
    },
    arialabel: {
      type: String,
      default: '',
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClass() {
      return {
        'submit-button--white-bg': this.white,
      }
    },
  },
}
