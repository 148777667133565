import { render, staticRenderFns } from "./error.vue?vue&type=template&id=2ad82a69"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSectionHeading: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Base/SectionHeading.vue').default,ButtonGeneric: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Button/ButtonGeneric.vue').default,ButtonLink: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Button/ButtonLink.vue').default})
