
import { FadeTransition } from 'vue2-transitions'
import { mapState } from 'vuex'

export default {
  components: {
    FadeTransition,
  },

  data() {
    return {
      brokerResults: [],
      mediaResults: [],
      pageResults: [],
      companiesIndex: null,
      generalIndex: null,
      timerId: null,
      searchIsDirty: false,
    }
  },

  computed: {
    ...mapState('ui', ['search']),
  },

  watch: {
    search: {
      deep: true,
      handler(value) {
        this.performSearch()
      },
    },
  },

  mounted() {
    if (this.search.query) {
      this.searchIsDirty = true
      this.performSearch()
    }

    // Focus input
    this.$refs.searchComponent.$el.querySelector('input#search').focus()
  },

  created() {
    this.companiesIndex = this.$algolia.initIndex('companies')
    this.mediaIndex = this.$algolia.initIndex('cms_pages')
    this.staticPagesIndex = this.$algolia.initIndex('static_pages')
  },

  methods: {
    async performSearch() {
      this.searchIsDirty = true
      const queries = [
        {
          indexName: 'companies',
          query: this.search.query,
          params: {
            filters: 'active:true',
          },
        },
        {
          indexName: 'cms_pages',
          query: this.search.query,
          params: {
            filters: 'published:true',
          },
        },
        {
          indexName: 'static_pages',
          query: this.search.query,
          params: {},
        },
      ]

      const {
        results: [brokerResults, mediaResults, staticPagesResults],
      } = await this.$algolia.multipleQueries(queries)

      this.brokerResults = brokerResults.hits.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      )
      this.mediaResults = mediaResults.hits
      this.pageResults = staticPagesResults.hits
    },

    async onQueryChange(value) {
      this.query = value
      await this.performSearch()
      this.searchIsDirty = true
    },
  },
}
