import ltng from '../api/ltng'
import wbn from '../api/wbn'

export default function (ctx, inject) {
  const ltngClient = ctx.$axios.create({
    baseURL: ctx.$config.ltngApi,
  })

  const wbnClient = ctx.$axios.create({
    baseURL: ctx.$config.wbnApi,
  })

  if (ctx.$auth.loggedIn) {
    wbnClient.setToken(ctx.$auth.strategy.token.get())
  }

  wbnClient.interceptors.response.use(
    (response) => response,
    function (error) {
      const originalRequest = error.config

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true

        // https://wbn.us.auth0.com/oauth/token

        wbnClient
          .post(`${ctx.$config.auth0.domain}/oauth/token`, {
            grant_type: 'refresh_token',
            client_id: ctx.$config.auth0.clientId,
            refresh_token: ctx.$auth.strategy.refreshToken.get(),
          })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              ctx.$auth.strategy.token.set(res.data.access_token)
              return wbnClient(originalRequest)
            }
          })
          .catch(() => {
            ctx.store.dispatch('user/logoutUser')
          })
      }

      return Promise.reject(error)
    }
  )

  inject('api', {
    ltng: ltng(ltngClient),
    wbn: wbn(wbnClient),
  })
}
