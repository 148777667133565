import { render, staticRenderFns } from "./ButtonPrimary.vue?vue&type=template&id=2e71aa99&scoped=true"
import script from "./ButtonPrimary.vue?vue&type=script&lang=js"
export * from "./ButtonPrimary.vue?vue&type=script&lang=js"
import style0 from "./ButtonPrimary.vue?vue&type=style&index=0&id=2e71aa99&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e71aa99",
  null
  
)

export default component.exports