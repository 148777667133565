import { render, staticRenderFns } from "./InnerHeader.vue?vue&type=template&id=dc4d2ed6&scoped=true"
import script from "./InnerHeader.vue?vue&type=script&lang=js"
export * from "./InnerHeader.vue?vue&type=script&lang=js"
import style0 from "./InnerHeader.vue?vue&type=style&index=0&id=dc4d2ed6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc4d2ed6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonIcon: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Button/ButtonIcon.vue').default})
