
export default {
  name: 'PageHeader',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: '',
    },
    crumbs: {
      type: Array,
      required: true,
    },
    page: {
      type: Object,
      default: null,
    },
    introtext: {
      type: String,
      default: '',
    },
  },
}
