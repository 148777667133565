
import smoothReflow from 'vue-smooth-reflow'

export default {
  name: 'SmoothReflow',

  mixins: [smoothReflow],

  props: {
    tag: {
      type: String,
      default: 'div',
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    this.$smoothReflow({
      property: ['height', 'width', 'transform'],
      transition: 'height .333s ease, width .333s ease, transform .333s ease',
      ...this.options,
    })
  },
}
