export default function (axios) {
  return {
    contacts(params) {
      return axios.get('/reports/member-contacts', params)
    },

    companies(params) {
      return axios.get('/reports/member-companies', params)
    },
  }
}
