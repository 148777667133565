
import { mapState } from 'vuex'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('user', ['companyUser', 'location', 'company']),
  },

  methods: {
    logout() {
      this.$router.push('/auth/signing-out')
    },
  },
}
