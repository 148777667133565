
import { mapActions, mapState } from 'vuex'
import { SlideXLeftTransition } from 'vue2-transitions'
import vClickOutside from 'v-click-outside'

export default {
  components: {
    SlideXLeftTransition,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      vcoConfig: {
        handler: this.vcoClickHandler,
        middleware: this.vcoClickMiddleware,
      },
    }
  },

  computed: {
    ...mapState(['ui']),
  },

  watch: {
    '$route.fullPath'() {
      this.$store.dispatch('ui/closeNav')
    },
  },

  methods: {
    ...mapActions('ui', ['toggleNav']),

    vcoClickHandler() {
      this.toggleNav()
    },

    vcoClickMiddleware(event) {
      const popover = document.querySelector('.tooltip.popover')

      if (popover && popover.contains(event.target)) return false
      return true
    },
  },
}
