
import { SlideXLeftTransition, FadeTransition } from 'vue2-transitions'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    FadeTransition,
    SlideXLeftTransition,
  },

  data() {
    return {
      otp: '',
      now: this.$moment().unix(),
      intervalId: null,
      isLoading: false,
    }
  },

  computed: {
    ...mapState('user', ['login']),

    computedExpiryCountdown() {
      return this.$moment
        .utc(
          this.$moment
            .unix(this.login.otpExpiry)
            .diff(this.$moment.unix(this.now))
        )
        .format('m:ss')
    },
  },

  watch: {
    otp(value) {
      if (value.length === 6) this.onSendCode()
    },
  },

  mounted() {
    if (this.login.otpRequested) {
      this.intervalId = setInterval(() => {
        this.now = this.$moment().unix()
      }, 1000)
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
  },

  methods: {
    ...mapActions('user', [
      'requestPasswordlessOTP',
      'signInWithOTP',
      'setEmail',
      'resetOtp',
    ]),

    focusCodeInput() {
      this.$refs.codeInput && this.$refs.codeInput.focus()
    },

    async onRequestCode() {
      await this.requestPasswordlessOTP(this.login.email)
      this.intervalId = setInterval(() => {
        this.now = this.$moment().unix()
      }, 1000)
    },

    async onSendCode() {
      if (this.isLoading) return

      this.isLoading = true

      try {
        await this.signInWithOTP({
          email: this.login.email,
          otp: this.otp,
        })
      } catch (error) {
        this.$bugsnag.notify(error, (event) => {
          event.context = 'AuthLogin.onSendCode'
          event.addMetadata('otp', this.otp)
          event.addMetadata('store', this.$store.state)
        })
      }

      this.isLoading = false
    },

    onResendCode() {
      this.otp = ''
      this.isLoading = false
      this.resetOtp()
    },
  },
}
