import Vue from 'vue'
import VModal from 'vue-js-modal/dist/ssr.nocss'
import 'vue-js-modal/dist/styles.css'

Vue.use(VModal, {
  dynamicDefaults: {
    height: 'auto',
    scrollable: false,
    adaptive: true,
    focusTrap: true,
    clickToClose: true,
    minWidth: 300,
    width: '100%',
    maxWidth: 1200,
    styles: {
      overflow: 'visible',
    },
  },
})
