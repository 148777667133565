export default function (axios) {
  return {
    getLocations(params) {
      return axios.get('/locations', params)
    },

    getLocation(id, params = {}) {
      return axios.get(`/locations/${id}`, params)
    },

    createLocation(body, params = {}) {
      return axios.post(`/locations`, body, params)
    },

    updateLocation(id, body, params = {}) {
      return axios.put(`/locations/${id}`, body, params)
    },

    deleteLocation(id, params = {}) {
      return axios.delete(`/locations/${id}`, params)
    },
  }
}
