
export default {
  props: {
    id: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: 'name',
    },

    value: {
      type: String,
      default: 'Option',
    },

    checked: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
  },
}
