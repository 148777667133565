import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=6163ff1c&scoped=true"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"
import style0 from "./Search.vue?vue&type=style&index=0&id=6163ff1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6163ff1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationSearchInput: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Search/SearchInput.vue').default,NavigationSearchPageResult: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Search/PageResult.vue').default,NavigationSearchNewsResult: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Search/NewsResult.vue').default,NavigationSearchBrokerResult: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Search/BrokerResult.vue').default})
