import { render, staticRenderFns } from "./WhoWeAreHeader.vue?vue&type=template&id=6d30fcb9&scoped=true"
import script from "./WhoWeAreHeader.vue?vue&type=script&lang=js"
export * from "./WhoWeAreHeader.vue?vue&type=script&lang=js"
import style0 from "./WhoWeAreHeader.vue?vue&type=style&index=0&id=6d30fcb9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d30fcb9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasePageHeader: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Base/PageHeader.vue').default,GenericSmoothReflow: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Generic/SmoothReflow.vue').default})
