import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=89a79a92&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=89a79a92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89a79a92",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonIcon: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Button/ButtonIcon.vue').default})
