import specialties from './specialties'
import countries from './countries'
import companies from './companies'
import members from './members'
import companyUsers from './companyUsers'
import users from './users'
import uploads from './uploads'
import locations from './locations'
import reports from './reports'
import wellness from './wellness'

export default function (axios) {
  return {
    specialties: specialties(axios),
    countries: countries(axios),
    companies: companies(axios),
    members: members(axios),
    companyUsers: companyUsers(axios),
    users: users(axios),
    locations: locations(axios),
    uploads: uploads(axios),
    reports: reports(axios),
    wellness: wellness(axios),
    $client: axios,
  }
}
