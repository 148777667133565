import createPersistedState from 'vuex-persistedstate'
import { version } from '@/package.json'

export default ({ store, $config }) => {
  if (process.client) {
    const storageVersionKey = `${$config.localStorage.prefix}.${$config.localStorage.keys.version}`
    const clientVersion = localStorage.getItem(storageVersionKey)

    if (clientVersion !== version) {
      console.info(
        `[WBN] Moving from ${clientVersion} to ${version}. Clearing WBN localStorage`
      )
      localStorage.removeItem(storageVersionKey)
      localStorage.setItem(storageVersionKey, version)
    }
  }

  createPersistedState({
    key: `${$config.localStorage.prefix}.${$config.localStorage.keys.persistedState}`,
    paths: ['auth', 'user', 'ui', 'admin'],
  })(store)
}
