
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs'

export default {
  components: {
    FadeTransition,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClass() {
      return {
        loading: this.loading,
      }
    },
  },
}
