const otpExpiryInMinutes = 5

const defaultState = () => ({
  login: {
    email: '',
    otpRequested: null,
    otpExpiry: null,
    isLoadingUser: false,
  },
  mustSignTC: false,
  data: {
    avatarImageId: null,
    defaultAvatar: null,
    factsheetImageId: null,
    defaultFactsheet: null,
  },
  companyUser: {},
  company: {},
  location: {},
  user: {},
})

export const state = defaultState

export const getters = {
  canManageCompany: (state) => {
    return (
      state.user.permissions &&
      state.user.permissions.find(
        (permission) => permission.role.toLowerCase() === 'manager'
      )
    )
  },

  isAdmin: (state) =>
    state.user.permissions &&
    !!state.user.permissions.find(
      (permission) =>
        permission.module.toLowerCase() === 'system' &&
        permission.role.toLowerCase() === 'admin'
    ),

  isManager: (state) =>
    state.user.permissions &&
    !!state.user.permissions.find(
      (permission) => permission.role.toLowerCase() === 'manager'
    ),
}

export const mutations = {
  SET_EMAIL(state, email) {
    state.login = {
      ...state.login,
      email,
    }
  },

  SET_OTP_REQUESTED(state, payload) {
    state.login = {
      ...state.login,
      otpRequested: payload,
    }
  },

  SET_OTP_EXPIRY(state, payload) {
    state.login = {
      ...state.login,
      otpExpiry: payload,
    }
  },

  SET_LOADING_USER(state, payload) {
    state.login = {
      ...state.login,
      isLoadingUser: payload,
    }
  },

  SET_MUST_SIGN_TC(state, payload) {
    state.mustSignTC = payload
  },

  SET_USER_DATA(state, payload) {
    state.data = {
      ...state.data,
      ...payload,
    }
  },

  SET_USER(state, payload) {
    state.user = payload
  },

  SET_COMPANY_USER(state, payload) {
    state.companyUser = payload
  },

  SET_COMPANY(state, payload) {
    state.company = payload
  },

  SET_LOCATION(state, payload) {
    state.location = payload
  },

  RESET_OTP(state) {
    state.login = {
      ...defaultState().login,
    }
  },

  LOGOUT_USER(state) {
    const initial = defaultState()

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },

  RESET(state) {
    const initial = defaultState()

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

export const actions = {
  async requestPasswordlessOTP({ commit }, email) {
    try {
      const { _id } = await this.$axios.$post(
        `${this.$config.auth0.domain}/passwordless/start`,
        {
          client_id: this.$config.auth0.clientId,
          connection: 'email',
          email,
          send: 'code',
        }
      )

      if (_id) {
        commit('SET_OTP_REQUESTED', this.$moment().unix())
        commit(
          'SET_OTP_EXPIRY',
          this.$moment().add(otpExpiryInMinutes, 'minutes').unix()
        )
        return Promise.resolve()
      } else {
        throw new Error('Failed to request OTP')
      }
    } catch (error) {
      if (error.response && error.response.data.error_description) {
        this.$toast.error(error.response.data.error_description)
      }
    }
  },

  async signInWithOTP({ dispatch }, { email, otp }) {
    try {
      const { access_token: accessToken, refresh_token: refreshToken } =
        await this.$axios.$post(`${this.$config.auth0.domain}/oauth/token`, {
          client_id: this.$config.auth0.clientId,
          grant_type: this.$config.auth0.grantType,
          audience: this.$config.auth0.audience,
          scope: this.$config.auth0.scope,
          username: email,
          realm: 'email',
          otp: otp.trim(),
        })

      return dispatch('signInUserWithPasswordlessTokens', {
        accessToken,
        refreshToken,
      })
    } catch (error) {
      if (error.response && error.response.data.error_description)
        this.$toast.error(error.response.data.error_description)
      throw error
    }
  },

  async signInUserWithPasswordlessTokens(
    { dispatch, commit },
    { accessToken, refreshToken }
  ) {
    dispatch('setLoadingUser', true)
    this.$auth.strategy.token.set(accessToken)
    this.$auth.strategy.refreshToken.set(refreshToken)
    this.$api.wbn.$client.setToken(accessToken, 'Bearer')

    try {
      await this.$auth.fetchUser()
      this.$bugsnag.setUser(
        this.$auth.user.id,
        this.$auth.user.email,
        `${this.$auth.user.given_name} ${this.$auth.user.family_name}`
      )

      await dispatch('setUser', this.$auth.user)
      dispatch('getCompanyUser')
      dispatch('getUserAvatar')
      commit('SET_OTP_EXPIRY', null)
      commit('SET_OTP_REQUESTED', null)
    } catch (error) {
      this.$bugsnag.notify(error)
    } finally {
      dispatch('setLoadingUser', false)
    }
  },

  async setOtpRequested({ commit }, payload) {
    await commit('SET_OTP_REQUESTED', payload)
  },

  async setEmail({ commit }, email) {
    await commit('SET_EMAIL', email)
  },

  async setLoadingUser({ commit }, payload) {
    await commit('SET_LOADING_USER', payload)
  },

  async setMustSignTC({ commit }, payload) {
    await commit('SET_MUST_SIGN_TC', payload)
  },

  async getUserAvatar({ commit, rootState }) {
    const { auth } = rootState
    if (auth.user) {
      const { data } = await this.$api.wbn.uploads.getUploads({
        params: {
          upload_type: 'assets',
          prefix: auth.user.id,
          limit: 1,
          sort_by: 'created_on',
          dir: 'desc',
        },
      })

      if (data?.results?.[0]?.cf_file_details?.id) {
        commit('SET_USER_DATA', {
          avatarImageId: data.results[0].cf_file_details.id,
        })
      } else {
        commit('SET_USER_DATA', {
          defaultAvatar: rootState.auth.user.picture,
        })
      }
    }
  },

  async setUserData({ commit }, payload) {
    await commit('SET_USER_DATA', payload)
  },

  async setUser({ commit }, payload) {
    await commit('SET_USER', payload)
  },

  async resetOtp({ commit }) {
    await commit('RESET_OTP')
  },

  async getCompany({ commit, state }) {
    try {
      const { data } = await this.$api.wbn.companies.getCompany(
        state.companyUser.company
      )

      commit('SET_COMPANY', data)
      return data
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async getLocation({ commit, state }) {
    try {
      let locationId
      if (Array.isArray(state.companyUser.location)) {
        locationId = state.companyUser.location[0]
      } else {
        locationId = state.companyUser.location
      }

      const { data } = await this.$api.wbn.locations.getLocation(locationId)

      commit('SET_LOCATION', data)
      return data
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async getCompanyUser({ commit, dispatch, state }) {
    try {
      const { data } = await this.$api.wbn.companyUsers.getCompanyUsers({
        params: { user: this.$auth.user.id },
      })

      if (data.results.length) {
        commit('SET_COMPANY_USER', data.results[0])
        await dispatch('getCompany')
        await dispatch('getLocation')
      } else {
        this.$bugsnag.notify(new Error('Company User not found'), (event) => {
          event.context = 'store.user.getCompanyUser.CompanyUserNoResults'
          event.addMetadata('store', state)
          event.addMetadata('auth', this.$auth.user)
        })
        commit('SET_COMPANY_USER', {})
        commit('SET_COMPANY', {})
      }
    } catch (error) {
      this.$bugsnag.notify(error, (event) => {
        event.context = 'store.user.getCompanyUser'
        event.addMetadata('store', state)
        event.addMetadata('auth', this.$auth.user)
      })
    }
  },

  async logoutUser({ commit }) {
    await commit('LOGOUT_USER')
    this.$auth.logout()
  },

  async reset({ commit }) {
    return await commit('RESET')
  },
}
