export default function (axios) {
  return {
    getCompanyUsers(params) {
      return axios.get('/company-users', params)
    },

    updateCompanyUser(id, data) {
      return axios.put(`/company-users/${id}`, data)
    },

    createCompanyUser(payload, params = {}) {
      return axios.post(`/company-users/`, payload, params)
    },

    deleteCompanyUser(id, params = {}) {
      return axios.delete(`/company-users/${id}`, params)
    },
  }
}
