
// import { VImg } from 'vuetensils/src/components'
import VLazyImage from 'v-lazy-image/v2'

export default {
  components: {
    VLazyImage,
  },

  props: {
    captions: {
      type: Array,
      default: () => [],
    },

    caption: {
      type: String,
      default: '',
    },

    src: String,

    alt: {
      type: String,
      default: '',
    },

    srcPlaceholder: {
      type: [String, Boolean],
      default: '',
    },

    captionPosition: {
      type: String,
      default: 'left',
    },

    aspectClasses: {
      type: String,
      default: '',
    },

    lazy: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    computedCaptionClass() {
      return {
        'text-left': this.captionPosition === 'left',
        'text-center': this.captionPosition === 'center',
        'text-right': this.captionPosition === 'right',
      }
    },

    computedWrapperClasses() {
      return [
        this.aspectClasses,
        {
          'no-image': !this.src,
        },
      ]
    },

    computedSrc() {
      if (this.src) {
        return this.src
      }

      if (this.defaultSrc) {
        return this.defaultSrc
      }

      return false
    },
  },
}
