export default function (axios) {
  return {
    getCompanies(params) {
      let path = '/companies'
      if (params.query) path = `${path}?${params.query}`
      delete params.query
      return axios.get(path, params)
    },

    getCompany(id, params = {}) {
      return axios.get(`/companies/${id}`, params)
    },

    updateCompany(id, body, params = {}) {
      return axios.put(`/companies/${id}`, body, params)
    },
  }
}
