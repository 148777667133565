import { render, staticRenderFns } from "./ExpandableMenu.vue?vue&type=template&id=60103d9c"
import script from "./ExpandableMenu.vue?vue&type=script&lang=js"
export * from "./ExpandableMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GenericTransitionExpand: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Generic/TransitionExpand.vue').default})
