export const getExcerpt = (string, lengthOrBreakpoints, $mq = null) => {
  let length

  if ($mq && typeof lengthOrBreakpoints === 'object') {
    length = lengthOrBreakpoints[$mq]
  } else {
    length = lengthOrBreakpoints
  }

  const strippedString = string.replace(/<[^>]*>?/gm, '')

  if (strippedString.length > length) {
    return strippedString.substring(0, length) + '...'
  }

  return strippedString
}

export const createSEOMeta = (data) => [
  { hid: 'og:title', property: 'og:title', content: data.title || '' },
  {
    hid: 'description',
    property: 'description',
    content: data.description || '',
  },
  {
    hid: 'og:description',
    property: 'og:description',
    content: data.description || '',
  },
  { hid: 'og:image', property: 'og:image', content: data.image || '' },
  { hid: 'og:url', property: 'og:url', content: data.url },
  {
    hid: 'twitter:card',
    property: 'twitter:card',
    content: data.cardTyoe || 'summary_large_image',
  },
]

export const getUploadId = async (axios, params) => {
  const { id: upload_id } = await axios.$post('/uploads', params)

  return upload_id
}

export const uploadFile = async (axios, uploadFile, params) => {
  // params = {
  //   type: ['image', 'video'],
  //   owner_id: owningObjectId,
  //   me: uploadingAliasOrUserId,
  //   upload_type: ['assets', 'community', 'gallery'],
  // }

  const name = uploadFile.name.split('.')[0]
  const ext = uploadFile.name.split('.').slice(-1)[0]
  const { id: upload_id } = await axios.$post('/uploads', {
    ...params,
  })

  if (!upload_id) throw new Error('Could not get upload ID')

  const data = new FormData()
  data.append('file', uploadFile)

  const file = await axios.$post('/uploads', data, {
    params: {
      type: params.type,
      upload_id,
      ext,
      name,
    },
  })

  return file
}

export default {
  getExcerpt,
  createSEOMeta,
  uploadFile,
}
