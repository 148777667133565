export default function (axios) {
  return {
    getUser(id, params = {}) {
      return axios.get(`/users/${id}`, params)
    },

    putUser(id, data) {
      return axios.put(`/users/${id}`, data)
    },

    createUser(payload, params = {}) {
      return axios.post(`/users/`, payload, params)
    },

    deleteUser(id = '', params = {}) {
      return axios.delete(`/users/${id}`, params)
    },
  }
}
