
export default {
  props: ['error'],

  computed: {
    computedErrorHeading() {
      switch (this.error.statusCode) {
        case 404:
          return 'Page not found'
        default:
          return 'An error occurred'
      }
    },
  },
}
