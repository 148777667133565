import { render, staticRenderFns } from "./default.vue?vue&type=template&id=536c4a14&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=536c4a14&prod&lang=scss"
import style1 from "./default.vue?vue&type=style&index=1&id=536c4a14&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536c4a14",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutInnerHeader: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Layout/InnerHeader.vue').default,LayoutFooter: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Layout/Footer.vue').default,NavigationPanel: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Panel.vue').default,GenericScrollToTop: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Generic/ScrollToTop.vue').default})
