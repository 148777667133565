
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['canManageCompany', 'isAdmin']),

    allowOnlyMembers() {
      if (this.$auth.user.is_carrier || this.$auth.user.is_associate) {
        return false
      } else {
        return true
      }
    },
  },
}
