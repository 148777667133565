
export default {
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      expanded: this.isExpanded,
    }
  },

  watch: {
    isExpanded(value) {
      if (value) {
        this.expanded = true
      }
    },
  },

  methods: {
    toggle() {
      this.expanded = !this.expanded
    },
  },
}
