
import { FadeTransition } from 'vue2-transitions'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    FadeTransition,
  },

  computed: {
    ...mapState(['ui']),
    computedClass() {
      return {
        'nav-open': this.ui.navIsOpen,
        'controls--dark': !['/', '/members'].includes(this.$route.fullPath),
      }
    },
  },

  methods: {
    ...mapActions('ui', ['toggleNavSearch', 'closeNav', 'openNav']),
  },
}
