export default function (axios) {
  return {
    getUploads(params = {}) {
      return axios.get(`/uploads/`, params)
    },

    getUpload(id, params = {}) {
      return axios.put(`/uploads/${id}`, params)
    },
  }
}
