const defaultState = () => ({
  navIsOpen: false,
  navSearchIsOpen: false,
  search: {
    query: '',
    section: 'all',
  },
  brokerSearch: {
    query: '',
    filters: [],
  },
  whoWeAre: {
    heading: '',
    subHeading: '',
    breadcrumbs: [],
  },
  globe: {
    render: true,
  },
})

export const state = defaultState

export const mutations = {
  TOGGLE_NAV(state) {
    state.navIsOpen = !state.navIsOpen
  },

  TOGGLE_NAV_SEARCH(state) {
    state.navSearchIsOpen = !state.navSearchIsOpen
  },

  OPEN_NAV(state) {
    state.navIsOpen = true
    document.body.classList.add('menu-open')
  },

  CLOSE_NAV(state) {
    state.navIsOpen = false
    document.body.classList.remove('menu-open')
  },

  OPEN_NAV_SEARCH(state) {
    state.navSearchIsOpen = true
  },

  CLOSE_NAV_SEARCH(state) {
    state.navSearchIsOpen = false
  },

  SET_WHO_WE_ARE(state, payload) {
    state.whoWeAre = {
      ...defaultState.whoWeAre,
      ...payload,
    }
  },

  SET_SEARCH_OPTIONS(state, payload) {
    state.search = {
      ...state.search,
      ...payload,
    }
  },

  SET_BROKER_SEARCH_QUERY(state, query) {
    state.brokerSearch.query = query
  },

  SET_BROKER_SEARCH_FILTERS(state, filters) {
    state.brokerSearch.filters = filters
  },

  CLEAR_BROKER_SEARCH_FILTERS(state) {
    state.brokerSearch.filters = []
  },

  SET_SEARCH_SECTION(state, section) {
    state.search.section = section
  },

  SET_GLOBE(state, payload) {
    state.globe = {
      ...state.globe,
      ...payload,
    }
  },

  RESET(state) {
    const initial = defaultState()

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

export const actions = {
  async toggleNav({ dispatch, state }) {
    if (state.navIsOpen) {
      await dispatch('closeNav')
    } else {
      await dispatch('openNav')
    }
  },

  async toggleNavSearch({ dispatch, state }) {
    if (state.navSearchIsOpen) {
      await dispatch('closeNavSearch')
    } else {
      await dispatch('openNavSearch')
    }
  },

  async openNav({ commit }) {
    await commit('OPEN_NAV')
  },

  async closeNav({ commit }) {
    return await Promise.all([commit('CLOSE_NAV'), commit('CLOSE_NAV_SEARCH')])
  },

  async openNavSearch({ commit }, payload) {
    if (payload) await commit('SET_SEARCH_OPTIONS', payload)
    return await Promise.all([commit('OPEN_NAV_SEARCH'), commit('OPEN_NAV')])
  },

  async closeNavSearch({ commit }) {
    return await Promise.all([commit('CLOSE_NAV'), commit('CLOSE_NAV_SEARCH')])
  },

  async setWhoWeAre({ commit }, payload = {}) {
    await commit('SET_WHO_WE_ARE', payload)
  },

  async setSearchOptions({ commit }, payload) {
    await commit('SET_SEARCH_OPTIONS', payload)
  },

  async setSearchSection({ commit }, payload) {
    await commit('SET_SEARCH_SECTION', payload)
  },

  async setBrokerSearchQuery({ commit }, payload) {
    await commit('SET_BROKER_SEARCH_QUERY', payload)
  },

  async setBrokerSearchFilters({ commit }, payload) {
    await commit('SET_BROKER_SEARCH_FILTERS', payload)
  },

  async clearBrokerSearchFilters({ commit }) {
    await commit('CLEAR_BROKER_SEARCH_FILTERS')
  },

  async performBrokerSearch({ state }) {
    const params = {
      active: true,
    }

    state.brokerSearch.filters.forEach((filter) => {
      switch (filter.name) {
        case 'country':
          params.representing = filter.value
          break
        case 'region':
          params.location_regions = filter.value
          break
        case 'specialty':
          params.specialties = filter.value
          break
        case 'is_member':
          params.is_associate = false
          params.is_carrier = false
          break
        default:
          params[filter.name] = filter.value
      }
    })

    params.limit = 500
    params.keyword = state.brokerSearch.query
    if (params.keyword === 'India') params.keyword = '"India"'
    const { data } = await this.$api.wbn.companies.getCompanies({ params })

    return data.results.sort((a, b) =>
      a.name > b.name ? 1 : a.name < b.name ? -1 : 0
    )
  },

  async setGlobe({ commit }, payload) {
    return await commit('SET_GLOBE', payload)
  },

  async reset({ commit }) {
    return await commit('RESET')
  },
}
