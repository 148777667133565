
import { SlideXRightTransition } from 'vue2-transitions'
import { mapState } from 'vuex'

export default {
  components: {
    SlideXRightTransition,
  },
  scrollToTop: true,

  computed: {
    ...mapState(['ui']),
  },
}
