import Vue from 'vue'
import Cleave from 'cleave.js'
import { AsYouTypeFormatter } from 'google-libphonenumber'

Cleave.AsYouTypeFormatter = AsYouTypeFormatter

Vue.directive('cleave', {
  inserted: (el, binding) => {
    if (!binding.hasOwnProperty('value') || binding.value) {
      el.cleave = new Cleave(el, binding.value || {})
    }
  },
  update: (el) => {
    if (el.cleave) {
      const event = new Event('input', { bubbles: true })
      setTimeout(function () {
        el.value = el.cleave.properties.result
        el.dispatchEvent(event)
      }, 100)
    }
  },
})
