import { render, staticRenderFns } from "./Panel.vue?vue&type=template&id=228fed20&scoped=true"
import script from "./Panel.vue?vue&type=script&lang=js"
export * from "./Panel.vue?vue&type=script&lang=js"
import style0 from "./Panel.vue?vue&type=style&index=0&id=228fed20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228fed20",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationSearch: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Search/Search.vue').default,NavigationMenu: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Menu.vue').default,UserCard: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/User/Card.vue').default,AuthLogin: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Auth/Login.vue').default})
