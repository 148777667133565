
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isDirty: false,
    }
  },

  computed: {
    ...mapState('ui', ['search']),
  },

  methods: {
    ...mapActions('ui', ['setSearchOptions']),

    onInput(event) {
      this.isDirty = true
      this.setSearchOptions({ query: event.target.value })
      this.$emit('query-change', event.target.value)
    },

    onSearchMembersOnly(event) {
      this.setSearchOptions({ membersOnly: event })
      this.$emit('search-members-only', this.searchMembersOnly)
    },
  },
}
