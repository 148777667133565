
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },

    highlightResult: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    computedRepresentedCountries() {
      const { representing = [] } = this.highlightResult

      if (!representing) return null

      if (representing.length > 5) {
        const truncatedCountries = representing.slice(0, 5)
        truncatedCountries.push({
          value: `+ ${representing.length - 5} more`,
        })

        return truncatedCountries
      }

      return representing
    },
  },
}
