export default function (axios) {
  return {
    getSpecialties(params) {
      return axios.get('/specialties', params)
    },

    createSpecialty(payload, params) {
      return axios.post('/specialties', payload, params)
    },

    deleteSpecialty(id, params) {
      return axios.delete(`/specialties/${id}`, params)
    },

    updateSpecialty(id, payload, params) {
      return axios.put(`/specialties/${id}`, payload, params)
    },
  }
}
