export default function ({ $toast }) {
  $toast.register(
    'saveChangesError',
    (payload) => {
      if (!payload.message) {
        return 'There was a problem saving your changes. Please try again later.'
      }

      return payload.message
    },
    {
      type: 'error',
    }
  )

  $toast.register(
    'changesSaved',
    (payload) => {
      if (!payload.message) {
        return 'Your changes have been saved'
      }

      return payload.message
    },
    {
      type: 'success',
    }
  )
}
