
export default {
  name: 'ButtonGeneric',
  props: {
    label: {
      type: String,
      default: 'name',
    },
  },
}
