export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async reset({ dispatch }) {
    return await Promise.all([
      dispatch('admin/reset', null, { root: true }),
      dispatch('ui/reset', null, { root: true }),
    ])
  },
}
