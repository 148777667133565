
export default {
  name: 'SectionHeading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: '',
    },
    subHeading: {
      type: String,
      default: '',
    },
    noBorder: {
      type: Boolean,
      default: true,
    },
  },
}
