const defaultState = () => ({
  company: {},
  companyUsers: [],
  isCompanyUsersLoading: false,
  companyUsersError: false,
  locations: [],
  locationsByCountry: {},
  specialties: [],
  isLoading: false,
})

export const state = defaultState

export const getters = {
  getCompanyLocations: (state) => state.locations,
  getCompanyLocationsByCountry: (state) => {
    // Sort locations by country, ex:
    // {
    //   Africa: Location[]
    // }
    let locationsByCountry = state.locations.reduce((acc, next) => {
      acc[next.country] = [...(acc[next.country] || []), next]

      return acc
    }, {})

    // Sort countries alphabetically
    locationsByCountry = Object.keys(locationsByCountry)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: locationsByCountry[key],
        }),
        {}
      )

    return locationsByCountry
  },
  getCompanyLocationCountries: (state) => {
    return Object.keys(state.locationsByCountry)
  },
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload
  },

  SET_COMPANY_USERS_LOADING(state, payload) {
    state.isCompanyUsersLoading = payload
  },

  SET_COMPANY_USERS_ERROR(state, payload) {
    state.companyUsersError = payload
  },

  UPDATE_COMPANY(state, payload) {
    state.company = payload
  },

  SET_COMPANY_USERS(state, payload) {
    state.companyUsers = payload
  },

  ADD_COMPANY_USER(state, payload) {
    state.companyUsers.push(payload)
  },

  UPDATE_COMPANY_USER(state, payload) {
    state.companyUsers = state.companyUsers.map((user) => {
      if (user.id === payload.id) {
        return {
          ...user,
          ...payload,
        }
      }

      return user
    })
  },

  REMOVE_COMPANY_USER(state, id) {
    state.companyUsers = state.companyUsers.filter((user) => user.id !== id)
  },

  SET_LOCATIONS(state, payload) {
    state.locations = payload

    const locationsByCountry = payload.reduce((acc, next) => {
      acc[next.country] = [
        ...(acc[next.country] ? acc[next.country] : []),
        next,
      ]

      return acc
    }, {})

    state.locationsByCountry = Object.keys(locationsByCountry)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: locationsByCountry[key],
        }),
        {}
      )
  },

  ADD_LOCATION(state, payload) {
    state.locations = [...state.locations, payload]

    const updatedLocationsByCountry = state.locationsByCountry
    if (updatedLocationsByCountry[payload.country]) {
      updatedLocationsByCountry[payload.country].push(payload)
    } else {
      updatedLocationsByCountry[payload.country] = [payload]
    }

    state.locationsByCountry = Object.keys(updatedLocationsByCountry)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: updatedLocationsByCountry[key],
        }),
        {}
      )
  },

  UPDATE_LOCATION(state, payload) {
    state.locations = state.locations.map((location) => {
      if (location.id === payload.id) {
        return {
          ...location,
          ...payload,
        }
      }

      return location
    })

    const updatedLocationsByCountry = {
      ...state.locationsByCountry,
      [payload.country]: state.locationsByCountry[payload.country].map(
        (location) => {
          if (location.id === payload.id) {
            return {
              ...location,
              ...payload,
            }
          }

          return location
        }
      ),
    }

    state.locationsByCountry = Object.keys(updatedLocationsByCountry)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: updatedLocationsByCountry[key],
        }),
        {}
      )
  },

  REMOVE_LOCATION(state, id) {
    state.locations = state.locations.filter((location) => location.id !== id)

    const updatedLocationsByCountry = state.locationsByCountry
    for (const country in updatedLocationsByCountry) {
      updatedLocationsByCountry[country] = updatedLocationsByCountry[
        country
      ].filter((location) => location.id !== id)
    }

    state.locationsByCountry = updatedLocationsByCountry
  },

  ADD_SPECIALTY(state, payload) {
    state.specialties.push(payload)
  },

  REMOVE_SPECIALTY(state, id) {
    state.specialties = state.specialties.filter(
      (specialty) => specialty.id !== id
    )
  },

  RESET(state) {
    const initial = defaultState()

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

export const actions = {
  async setLoading({ commit }, payload) {
    await commit('SET_LOADING', payload)
  },

  async getCompany({ commit, dispatch }, id) {
    dispatch('setLoading', true)
    try {
      const company = await this.$api.wbn.companies
        .getCompany(id)
        .then((res) => res.data)

      commit('UPDATE_COMPANY', company)
      dispatch('getLocations')
      // if (company.locations) commit('SET_LOCATIONS', company.locations)
    } catch (error) {
      this.$bugsnag.notify(error)
    } finally {
      dispatch('setLoading', false)
    }
  },

  async updateCompany({ commit, dispatch }, { id, payload }) {
    dispatch('setLoading', true)
    try {
      const updatedCompany = await this.$api.wbn.companies
        .updateCompany(id, payload)
        .then((res) => res.data)

      commit('UPDATE_COMPANY', updatedCompany)
      dispatch('getLocations')
    } catch (error) {
      this.$bugsnag.notify(error)
    } finally {
      dispatch('setLoading', false)
    }
  },

  async getCompanyUsers({ commit }, params) {
    commit('SET_COMPANY_USERS_ERROR', false)
    commit('SET_COMPANY_USERS_LOADING', true)
    try {
      let companyId

      if (typeof params === 'string') companyId = params

      if (typeof params === 'object') companyId = params.id

      const companyUsers = await this.$api.wbn.companyUsers
        .getCompanyUsers({
          params: { company: companyId, limit: 999 },
        })
        .then((res) => res.data.results)

      commit('SET_COMPANY_USERS', companyUsers)
    } catch (error) {
      commit('SET_COMPANY_USERS', [])
      commit('SET_COMPANY_USERS_ERROR', true)
      this.$bugsnag.notify(error)
    }
    commit('SET_COMPANY_USERS_LOADING', false)
  },

  async addCompanyUser(
    { commit, state },
    { user, companyUser, profileImage, factsheetImage }
  ) {
    // create user
    const createdUser = await this.$api.wbn.users
      .createUser({
        ...user,
        company: state.company.id,
      })
      .then((res) => res.data)

    if (!createdUser.id) {
      throw new Error('Error creating user')
    }

    // create company user
    let createdCompanyUser = await this.$api.wbn.companyUsers
      .createCompanyUser({
        ...companyUser,
        user: createdUser.id,
        company: state.company.id,
      })
      .then((res) => res.data)

    // upload images
    if (profileImage !== null) {
      const result = await this.$helpers.uploadFile(
        this.$api.wbn.$client,
        profileImage,
        {
          type: 'image',
          owner_id: createdUser.id,
          me: this.$auth.user.id,
          upload_type: 'assets',
        }
      )

      await this.$api.wbn.users.putUser(createdUser.id, {
        photo: result.cf_file_details.id,
      })
    }

    if (factsheetImage !== null) {
      const result = await this.$helpers.uploadFile(
        this.$api.wbn.$client,
        factsheetImage,
        {
          type: 'image',
          owner_id: companyUser.id,
          me: this.$auth.user.id,
          upload_type: 'assets',
        }
      )

      createdCompanyUser = await this.$api.wbn.companyUsers.updateCompanyUser(
        createdUser.id,
        {
          factsheet_img: result.cf_file_details.id,
        }
      )
    }

    commit('ADD_COMPANY_USER', {
      ...createdCompanyUser,
      given_name: createdUser.given_name,
      family_name: createdUser.family_name,
    })
    return Promise.resolve({
      ...createdCompanyUser,
      given_name: createdUser.given_name,
      family_name: createdUser.family_name,
    })
  },

  async updateCompanyUser({ commit }, { id, payload }) {
    try {
      const updatedCompanyUser = await this.$api.wbn.companyUsers
        .updateCompanyUser(id, payload)
        .then((res) => res.data)
      commit('UPDATE_COMPANY_USER', {
        ...payload,
        ...updatedCompanyUser,
      })
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async updateUser(_store, { id, payload }) {
    try {
      await this.$api.wbn.users.putUser(id, payload).then((res) => res.data)

      // commit('UPDATE_COMPANY_USER', updatedCompanyUser)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async removeCompanyUser({ commit }, id) {
    try {
      await this.$api.wbn.companyUsers
        .deleteCompanyUser(id)
        .then((res) => res.data)
      commit('REMOVE_COMPANY_USER', id)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async getLocations({ commit, state }) {
    try {
      const locations = await this.$api.wbn.locations
        .getLocations({
          params: {
            company: state.company.id,
            limit: 999,
          },
        })
        .then((res) => res.data.results)

      commit('SET_LOCATIONS', locations)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async addLocation({ commit, state }, payload) {
    try {
      const createdLocation = await this.$api.wbn.locations
        .createLocation({
          ...payload,
          company: state.company.id,
        })
        .then((res) => res.data)
      commit('ADD_LOCATION', createdLocation)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async updateLocation({ commit }, { id, payload }) {
    try {
      const updatedLocation = await this.$api.wbn.locations
        .updateLocation(id, payload)
        .then((res) => res.data)
      commit('UPDATE_LOCATION', updatedLocation)
    } catch (error) {
      this.$bugsnag.notify(error)
    }
  },

  async removeLocation({ commit }, id) {
    try {
      await this.$api.wbn.locations.deleteLocation(id)
      commit('REMOVE_LOCATION', id)
    } catch (error) {
      this.$bugsnag.notify(error)
      return Promise.reject(error)
    }
  },

  async reset({ commit }) {
    return await commit('RESET')
  },
}
