
export default {
  computed: {
    allowOnlyMembers() {
      if (this.$auth.user.is_carrier || this.$auth.user.is_associate) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    backToTop() {
      if (process.client) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    },

    async handleMemberLoginClick() {
      await this.$store.dispatch('ui/openNav')
      document.querySelector('#login-email-input').focus()
    },
  },
}
