export default function (axios) {
  return {
    getPages(params) {
      return axios.get('/pages', params)
    },

    getPage(id, params) {
      return axios.get(`/pages/${id}`, params)
    },
  }
}
