import { render, staticRenderFns } from "./who-we-are.vue?vue&type=template&id=4ec6945b&scoped=true"
import script from "./who-we-are.vue?vue&type=script&lang=js"
export * from "./who-we-are.vue?vue&type=script&lang=js"
import style0 from "./who-we-are.vue?vue&type=style&index=0&id=4ec6945b&prod&lang=scss"
import style1 from "./who-we-are.vue?vue&type=style&index=1&id=4ec6945b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec6945b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutInnerHeader: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Layout/InnerHeader.vue').default,NavigationWhoWeAreHeader: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/WhoWeAreHeader.vue').default,LayoutFooter: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Layout/Footer.vue').default,NavigationPanel: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Navigation/Panel.vue').default,GenericScrollToTop: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Generic/ScrollToTop.vue').default})
