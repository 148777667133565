import pages from './pages'
import forms from './forms'

export default function (axios) {
  return {
    pages: pages(axios),
    forms: forms(axios),
    $client: axios,
  }
}
